import { QuestionIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { Currency, Percentage } from "components/Number";
import { DEAL_TYPE } from "constants/dealConstants";
import moment from "moment";
import PropTypes from "prop-types";
import { transformAmortizationData } from "transformers/amortizationTransformer";

const AmortizationTableCell = ({ children, tooltip }) => (
  <SimpleGrid
    columns={2}
    gap={1}
    gridTemplateColumns={"min-content min-content"}
    whiteSpace={"nowrap"}
  >
    {tooltip && (
      <>
        <Box></Box>
        <Tooltip label={tooltip}>
          <Flex justify={"end"}>
            <Flex
              gap={1}
              mt={-1}
              mr={-1}
              mb={1}
              fontSize={"sm"}
              alignItems={"center"}
            >
              <Text>
                <u>more info</u>
              </Text>
              <QuestionIcon />
            </Flex>
          </Flex>
        </Tooltip>
      </>
    )}
    {children}
  </SimpleGrid>
);

const AmortizationTableCellRow = ({ label, value }) => (
  <>
    <Text>{label}: </Text>
    {value !== null ? (
      <Box>
        <Currency value={value} maximumFractionDigits={2} />
      </Box>
    ) : (
      <Text>N/A</Text>
    )}
  </>
);

const AmortizationTableCellTooltip = ({ installment, amortizationItem }) => {
  const { revenueStartDate, revenueEndDate, repayments } = amortizationItem;
  const { fee, revenueSharePct, revenueType, revenueMarginPct } = installment;

  return (
    <SimpleGrid
      columns={2}
      gap={1}
      gridTemplateColumns={"min-content min-content"}
      whiteSpace={"nowrap"}
      p={2}
    >
      {revenueStartDate && revenueEndDate && (
        <>
          <Text>Cohort Activity: </Text>
          <Text>{`${moment(revenueStartDate).format("MM/DD/YYYY")} - ${moment(
            revenueEndDate
          ).format("MM/DD/YYYY")}`}</Text>
        </>
      )}
      <>
        <Text>Fee: </Text>
        <Percentage value={fee} />
      </>
      {revenueSharePct && (
        <>
          <Text>Revenue Share: </Text>
          <Percentage value={revenueSharePct} />
        </>
      )}
      {revenueType && (
        <>
          <Text>Revenue Type: </Text>
          <Text>{revenueType}</Text>
        </>
      )}
      {revenueMarginPct && (
        <>
          <Text>Revenue Margin: </Text>
          <Percentage value={revenueMarginPct} />
        </>
      )}
      {repayments && repayments.length > 0 && (
        <>
          <Text fontWeight={800} textDecoration={"underline"} mt={1}>
            Repayments
          </Text>
          <Box></Box>
          {repayments.map((repayment, index) => (
            <>
              <Flex gap={1}>
                <Text>{moment(repayment.date).format("MM/DD/YYYY")}</Text>
                <Currency value={repayment.amount} maximumFractionDigits={2} />
              </Flex>
              <Text>{repayment.comment}</Text>
            </>
          ))}
        </>
      )}
    </SimpleGrid>
  );
};

const AmortizationTable = ({ data }) => {
  const transformedData = transformAmortizationData(data);
  console.log(transformedData);

  const nextRepaymentDateIndex = transformedData.dates.findIndex((date) => {
    const today = moment();
    return (
      moment(date).isAfter(today) ||
      moment(date).format("YYYY-MM-DD") === today.format("YYYY-MM-DD")
    );
  });

  const nextRepaymentCellBgColor = "twelve.green.200";

  return (
    <Box p={4}>
      <Table
        variant={"noPadding"}
        w={"max"}
        __css={{
          "td, th": {
            padding: "10px",
            border: "1px solid",
          },

          "td.borderRight, th.borderRight": {
            borderRight: "4px solid",
          },
        }}
      >
        <Thead>
          <Tr>
            <Th>Cohort Dates</Th>
            <Th>Transfer Date</Th>
            <Th>Purchase Price</Th>
            <Th className={"borderRight"}>Amount Sold</Th>
            {transformedData.dates.map((date, index) => (
              <Th
                key={date}
                bg={
                  index === nextRepaymentDateIndex && nextRepaymentCellBgColor
                }
              >
                {moment(date).format("MM/DD/YY")}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {transformedData.data.map((row, index) => (
            <Tr key={index}>
              <Td>
                {row.cohortStartDate && row.cohortEndDate
                  ? `${moment(row.cohortStartDate).format(
                      "MM/DD/YYYY"
                    )} - ${moment(row.cohortEndDate).format("MM/DD/YYYY")}`
                  : "N/A"}
              </Td>
              <Td>{moment(row.transferDate).format("MM/DD/YY")}</Td>
              <Td>
                <Currency value={row.purchasePrice} maximumFractionDigits={2} />
              </Td>
              <Td className={"borderRight"}>
                <Currency value={row.amountSold} maximumFractionDigits={2} />
              </Td>
              {row.amortization.map((item, index) => {
                if (!item) {
                  return (
                    <Td
                      bg={
                        index === nextRepaymentDateIndex &&
                        nextRepaymentCellBgColor
                      }
                      key={index}
                    >
                      -
                    </Td>
                  );
                }

                const dealType = row.dealType;

                return (
                  <Td
                    key={index}
                    bg={
                      index === nextRepaymentDateIndex &&
                      nextRepaymentCellBgColor
                    }
                  >
                    <AmortizationTableCell
                      tooltip={
                        <AmortizationTableCellTooltip
                          installment={row}
                          amortizationItem={item}
                        />
                      }
                    >
                      <AmortizationTableCellRow
                        label={"Minimum"}
                        value={item.minAmount}
                      />
                      {dealType === DEAL_TYPE.ROAS && (
                        <>
                          <AmortizationTableCellRow
                            label={"Predicted Revenue Share"}
                            value={item.revenueSharePredictedAmount}
                          />
                          <AmortizationTableCellRow
                            label={"Actual Revenue Share"}
                            value={item.revenueShareAmount}
                          />
                        </>
                      )}
                      <AmortizationTableCellRow
                        label={"Expected Repayment"}
                        value={item.amountToPay}
                      />
                      <AmortizationTableCellRow
                        label={"Actual Repayment"}
                        value={item.actualPaidAmount}
                      />
                    </AmortizationTableCell>
                  </Td>
                );
              })}
            </Tr>
          ))}
          <Tr fontWeight={800} m={10}>
            <Td></Td>
            <Td>Total</Td>
            <Td>
              <Currency
                value={transformedData.totals.purchasePrice}
                maximumFractionDigits={2}
              />
            </Td>
            <Td className={"borderRight"}>
              <Currency
                value={transformedData.totals.amountSold}
                maximumFractionDigits={2}
              />
            </Td>
            {transformedData.totals.amortization.map((total, index) => (
              <Td
                key={index}
                bg={
                  index === nextRepaymentDateIndex && nextRepaymentCellBgColor
                }
              >
                <AmortizationTableCell>
                  <AmortizationTableCellRow
                    label={"Excepted Repayment"}
                    value={total.amountToPay}
                  />
                  <AmortizationTableCellRow
                    label={"Actual Repayment"}
                    value={total.actualPaidAmount}
                  />
                  <AmortizationTableCellRow
                    label={"Remaining"}
                    value={total.leftToPay}
                  />
                </AmortizationTableCell>
                <Currency value={total.amount} maximumFractionDigits={2} />
              </Td>
            ))}
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

AmortizationTable.propTypes = {
  data: PropTypes.array,
};

export default AmortizationTable;
